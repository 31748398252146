import React from "react"
import { Helmet } from "react-helmet"
import PagesItems from "../components/PagesItems"

const Page = ({ data, classes, pageContext }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageContext.title} | Wellness.cz</title>
    </Helmet>
    <div className="relative px-4 sm:px-6 lg:px-8 lg:py-10 lg:pb-20 pb-20">
      <div
        dangerouslySetInnerHTML={{ __html: pageContext.pageContent }}
        className="mt-6 prose prose-yellow max-w-6xl lg:prose-lg text-gray-500 mx-auto pb-10"
      ></div>
      <div className=" prose prose-orange lg:prose-lg max-w-6xl text-gray-500 mx-auto pb-10">
        <PagesItems pageContext={pageContext} />
      </div>
    </div>
  </>
)

export default Page
